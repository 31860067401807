import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './app/Routes';
import { Provider } from 'react-redux';
import store from './app/store';
import "./assets/styles/App.scss";

class App extends Component {
  state = {
    data: null
  };

  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch(process.env.PUBLIC_URL + '/express_backend');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };

  render() {
    return (
      <div className="App ScheduleYourService">
        <Provider store={store} >
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Provider>
      </div>
    );
  }
}

export default App;