import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Cookies from 'universal-cookie';
import { completeScheduling, selectTimeSlotRange, selectTimeSlotDay, selectTimeSlotDayDefaultRange, storeNote } from './../../redux/actions/scheduleActions'
import { connect } from "react-redux";
import Summary from './../common/Summary/Summary';
import ScheduleDayPicker from './ScheduleDayPicker';
import ScheduleRangePicker from './ScheduleRangePicker';
import ScheduleConsentCall from './ScheduleConsentCall';
import ScheduleNote from './ScheduleNote';
import ZipCode from './../ZipCode/ZipCode';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import BreadCrumb from '../common/Header/BreadCrumb';
import analytics from '../../utils/tealium.js';
class Schedule extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            google: false,
            rocketMortgage: false
        }
    }
    
    componentDidMount() {
        let cookies = new Cookies();
        let partnerCookie = cookies.get('partner_id');
    
        if (partnerCookie === 'D2C-Google-Nest') {
            this.setState({
                google: true
            });
        } else if (partnerCookie === 'D2C-Rocket-Mortgage') {
            this.setState({
                rocketMortgage: true
            });
        }
    }

    renderCopy() {
        let retVal = null;
        let cookies = new Cookies();
        let clientCookie = cookies.get('clientname');

        if (clientCookie === 'D2C - Rocket Mortgage' || clientCookie === 'D2C%20-%20Rocket%20Mortgage' || this.state.rocketMortgage) {
            retVal = (
                <div className="copy-above-cal-wrap">
                    <div>
                        <p>
                            <b>Please select any available date.</b> We’ll deliver and install the entire Google Nest Bundle by Rocket Mortgage on your selected date. 
                        </p>
                    </div>
                </div>
            );
        } else {
            retVal = (
                <div className="copy-above-cal-wrap">
                <div>
                    <p>
                        <b>Already have your device?</b> Make sure they're purchased and available at your home prior to the installation date.
                    </p>
                </div>
                <div>
                    <p>
                        <b>Buying products through OnTech?</b> Select any available date. We’ll deliver and install on your selected date.
                    </p>
                </div>  
            </div> 
            )
        }
        return retVal;
    }

    renderBreadcrumb() {
        let retval = null;
        if (this.state.google) {
            retval = ( 
                <BreadCrumb crumbs={[
                    {
                        link: '/pages/nest',
                        text: 'Cart',
                        status: ''
                    },
                    {
                        link: null,
                        text: 'Schedule Install',
                        status: 'active'
                    }
                ]}></BreadCrumb>
            );
        } else if (this.state.rocketMortgage) {
            retval = (
                <BreadCrumb crumbs={[
                    {
                        link: '/products/rocket-mortgage-helpful-home-package-hdspcl',
                        text: 'Rocket Mortgage Package Detail',
                        status: ''
                    },
                    {
                        link: null,
                        text: 'Schedule Install',
                        status: 'active'
                    }
                ]}></BreadCrumb>
            )
        } else { 
            retval = (
                <BreadCrumb crumbs={[
                    {
                        link: '/cart',
                        text: 'Cart',
                        status: ''
                    },
                    {
                        link: null,
                        text: 'Schedule Install',
                        status: 'active'
                    }
                ]}></BreadCrumb>
            )
        }
        return retval;
    }

    renderRocketMortgageMessage() {
        let retVal = null;
        let cookies = new Cookies();
        let clientCookie = cookies.get('clientname');
        let rocketMortgage = cookies.get('ontech_showRocketMortgageCartClearedMessage');
        if ((clientCookie === 'D2C - Rocket Mortgage' || clientCookie === 'D2C%20-%20Rocket%20Mortgage' || this.state.rocketMortgage) && rocketMortgage === 'true') {
            retVal = (
                <div>
                    <p><strong>Your cart has been cleared to accommodate the Google Nest Bundle by Rocket Mortgage.</strong></p>
                </div>
            )
            cookies.remove('ontech_showRocketMortgageCartClearedMessage', {"domain": ".ontechsmartservices.com"});
        }

        return retVal;
    }
        
    render() {
        const {
            fetchingOrderDetails, fetchingSchedules, fetchedSchedulesError,
            fetchedSchedulesSuccess, slots, selectedTimeSlot,
            note, hasTechId, parent, clientName, handleTechDeliveryToday,
            handleNoteUpdate, handleTimeSlotSelectRange, handleTimeSlotSelectDay,
            selectedDay, handleNext, orderDetails,
            errorMessage, enableNote, startDay, google } = this.props;

        // console.log("*** Zip Code = " + zip + " ***");
        // console.log("*** hasTechId = " + hasTechId + " ***");
        // console.log("*** parent = " + parent + " ***");
        // console.log("*** clientname = " + clientName + " ***");
        
        return (
            <Container >
                <Row>
                    <Col md={8} sm={12} className="text-left pt-2">
                        {this.renderBreadcrumb()}
                    </Col>
                </Row>
                <Row className="height-inherit">
                    <Col md={8} sm={12} className="text-left pt-2 build-testing-100">
                        <div className="bb-1">
                            <Row>
                                <Col md={12} lg={6}>
                                    <div dangerouslySetInnerHTML={{ __html: "<!-- ***** Extensions 100 ***** -->" }} />

                                    <h2><strong>Schedule Your Service</strong> </h2>
                                    {this.renderCopy()}
                                    {this.renderRocketMortgageMessage()}
                                </Col>
                                <Col md={12} lg={6}>
                                    <ZipCode zipCode={ orderDetails.zipCode } items={ orderDetails.items } />
                                </Col>                                
                            </Row>
                            <Row >
                                <Col md={12} lg={6} className="mb-3 col-12">
                                    {
                                        (hasTechId || parent) && clientName === 'OSS' &&
                                        <div id="TechDeliveryWrapper">
                                            <button id="TechDelivery" className="btn btn-primary color-blue btn-blue br-30" title="Tech Delivery Today" onClick={() => handleTechDeliveryToday(orderDetails, hasTechId)}>Tech Delivery Today</button>
                                        </div>
                                    }
                                    <ScheduleDayPicker {...{ handleTimeSlotSelectDay, selectedDay, startDay }} />
                                    {/* <p className="below-calendar">Do you have your product on hand? Great, let's get you scheduled asap!
                                        If not, let's get you scheduled for a date when you will have received 
                                        your product so there are no delays in getting your smart home set up.
                                    </p> */}
                                </Col>
                                <Col md={12} lg={6}>
                                    <ScheduleRangePicker {...{ selectedDay, slots, fetchingSchedules, fetchedSchedulesSuccess, fetchedSchedulesError, handleTimeSlotSelectRange, selectedTimeSlot, errorMessage }} />
                                    
                                    {this.state.google
                                        ? <ScheduleConsentCall {...orderDetails} />
                                        : <span></span>
                                    }
                                    
                                    {
                                        (selectedTimeSlot && selectedTimeSlot.timeFrom && selectedTimeSlot.timeTo) ? (
                                            !enableNote &&
                                            <div className="text-right">
                                                <button id="Continue" className="btn btn-primary color-blue btn-blue br-30" title="Continue to checkout" style={{ marginBottom: '16px' }}
                                                    onClick={() => {
                                                        handleNext(selectedDay, selectedTimeSlot, orderDetails, hasTechId, parent, this.state.google)

                                                    }}>Next</button>
                                            </div>
                                        ) : (
                                            !enableNote &&
                                            <div className="text-right">
                                                <button id="Continue" disabled className="btn btn-primary color-blue btn-blue br-30" title="TimeSlot must be selected" style={{ marginBottom: '16px' }}>Next</button>
                                            </div>
                                        )
                                    }                                     
                                </Col>
                            </Row>
                            {
                                enableNote &&
                                <Row>
                                    <Col md={12}>
                                        <ScheduleNote {...{ handleNoteUpdate, note }} />
                                    </Col>
                                </Row>
                            }                           
                        </div>
                        {
                            (selectedTimeSlot && selectedTimeSlot.timeFrom && selectedTimeSlot.timeTo) ? (
                                enableNote &&
                                <div className="text-right mt-2 mb-2 mob-btn">
                                    <button id="Continue" className="btn btn-primary color-blue btn-blue br-30" title="Continue to checkout"
                                        onClick={() => {
                                            handleNext(selectedDay, selectedTimeSlot, orderDetails, hasTechId, parent, google)

                                        }}>Next</button>
                                </div>
                            ) : (
                                enableNote &&
                                <div className="text-right mt-2 mb-2 mob-btn">
                                    <button id="Continue" disabled className="btn btn-primary color-blue btn-blue br-30" title="TimeSlot must be selected">Next</button>
                                </div>
                            )
                        }
                    </Col>
                    <Col md={4} sm={12} className="bg-color-light">
                        <Summary tbd heading="Summary" {...orderDetails} fetchingCartSlots={fetchingOrderDetails} />
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {  
    return {
        selectedTimeSlotIndex: state.schedules.selectedTimeSlotIndex,
        selectedTimeSlot: state.schedules.selectedTimeSlot,
        selectedDay: state.schedules.selectedDay,
        slots: state.schedules.timeSlots,
        enableNote: state.schedules.enableNote,
        fetchingSchedules: state.schedules.fetchingDetails,
        fetchedSchedulesSuccess: state.schedules.fetchedDetailsSuccess,
        fetchedSchedulesError: state.schedules.fetchedDetailsError,
        errorMessage: state.schedules.errorMessage,
        hasTechId: state.schedules.hasTechId,
        parent: state.schedules.parent,
        clientName: state.schedules.clientName,
        startDay: state.schedules.startDay,
        fetchingOrderDetails: state.orders.fetchingDetails,
        fetchedOrderDetailsSuccess: state.orders.fetchedDetailsSuccess,
        fetchedOrderDetailsError: state.orders.fetchedDetailsError,
        google: state.google,
        note: state.schedules.note,
        orderDetails: state.orders
    }
}

const mapDispatchToProps = dispatch => {
    const cookies = new Cookies();
    const isMember = cookies.get("ontech_membership_active") || false;
    const customerEmailCookie = cookies.get("ontech_loggedInCustomerEmail");
    const isLoggedIn = (customerEmailCookie && customerEmailCookie.length) ? true : false;

    return {
        handleNext: (selectedDay, selectedTimeSlot, orderDetails, hasTechId, parent, google) => {
            analytics.view({
                "tealium_event": "schedule",
                "site_section": "page",
                "page_type": "page"
            });

            if (!selectedDay || !selectedTimeSlot || !orderDetails || !selectedTimeSlot.timeFrom || !selectedTimeSlot.timeTo) {
                return null;
            }

            // TODO: this was in the merge but seems wrong. Do we need this?
            //cookies.remove("ontech_membership_active");
            dispatch(completeScheduling(selectedDay, selectedTimeSlot, orderDetails, hasTechId, false, isMember, google, isLoggedIn));
        },
        handleTimeSlotSelectRange: (timeSlot) => {
            dispatch(selectTimeSlotRange(timeSlot));
        },
        handleTimeSlotSelectDay: (day, modifiers) => {
            if ('disabled' in modifiers && modifiers.disabled) {
                return null;
            }
            dispatch(selectTimeSlotDay(day));
            dispatch(selectTimeSlotDayDefaultRange(day));
        },
        handleNoteUpdate: (text) => {
            dispatch(storeNote(text));
        },
        handleTechDeliveryToday: (orderDetails) => {
            let timeFromMoment = moment();
            if (timeFromMoment.hours() % 2 === 1) {
                timeFromMoment.add(1, 'hours');
            }
            let timeToMoment = moment(timeFromMoment).add(2, 'hours');

            let timeSlot = {
                available: true,
                name: `${timeFromMoment.format("hh")}${timeFromMoment.format('a').substr(0, 1)}-${timeToMoment.format("hh")}${timeToMoment.format('a').substr(0, 1)}`,
                timeFrom: timeFromMoment.format("HH:00:00"),
                timeTo: timeToMoment.format("HH:00:00")
            }
            dispatch(completeScheduling(timeFromMoment.toDate(), timeSlot, orderDetails, true, true, isMember, false, isLoggedIn));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
