import React from 'react';
import 'react-day-picker/lib/style.css';
import { STORE_URL, REACT_APP_ENV, REACT_APP_TEALIUM_ENV, REACT_APP_DISH_URL } from '../../inc/config';

export default class EnvironmentInfoComponent extends React.Component {
    componentDidMount() {
        console.log('EnvironmentInfoComponent -> componentDidMount()');
    }

    render() {
        return (
            <div className="test-content">
                <h2>Environment Info Component</h2>
                <div className='blue-border'>
                    <div>
                        REACT_APP_ENV = { REACT_APP_ENV }<br/>
                        STORE_URL = { STORE_URL }<br/>
                        REACT_APP_TEALIUM_ENV = { REACT_APP_TEALIUM_ENV }<br/>
                        REACT_APP_DISH_URL = { REACT_APP_DISH_URL }
                    </div>                    
                </div>
            </div>
        );
    }
}
