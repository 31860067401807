import React from 'react';
import { centsToDollars } from '../../../utils/prices';

export class SummaryTotal extends React.PureComponent {
    render() {
        let { subtotal, tax, shipping_cost, discount_value } = this.props;

        shipping_cost = shipping_cost ? shipping_cost : 0;
        discount_value = discount_value ? discount_value: 0;
        tax = tax ? tax : 0;
        const total = subtotal + tax + shipping_cost - discount_value;
        
        return (
            <div className="text-left pos-relative p-2 bb-1">
                <div className="p-2 br-4">
                    <p><strong>Total <span className="text-right">{centsToDollars(total, '$0.00')}</span></strong></p>
                </div>
            </div>
        )
    }
}

    export default SummaryTotal;
