import * as Actions from './../actionTypes';
import moment from 'moment';
import Cookies from 'js-cookie';
import { ENABLE_NOTE } from './../../inc/config';
import { TECH_ID, PARENT, CLIENT_NAME } from '../../constants/cookies';

const today = moment().toDate();

const initialScheduleState = {
  timeSlots: [],
  fetchingDetails: true,
  fetchedDetailsSuccess: false,
  fetchedDetailserrorMessage: false,
  startDay: today,
  selectedDay: today,
  selectedTimeSlot: {},
  note: null,
  enableNote: ENABLE_NOTE,
  hasTechId: Cookies.get(TECH_ID) !== undefined,
  parent: Cookies.get(PARENT) !== undefined, 
  clientName: Cookies.get(CLIENT_NAME),
  errorMessage: null
};

// receiving response sent by action according to type of action
export default function scheduleReducers(state = initialScheduleState, action) {
  switch (action.type) {
    case Actions.TIMESLOTS_REQUEST:
      return {
        ...state,
        fetchingDetails: true,
        fetchedDetailsSuccess: false,
        fetchedDetailserrorMessage: false,
        errorMessage: null,
        timeSlots: [],
      };

    case Actions.TIMESLOTS_SUCCESS:
      return {
        ...state,
        fetchingDetails: false,
        fetchedDetailsSuccess: true,
        fetchedDetailserrorMessage: false,
        errorMessage: null,
        timeSlots: action.payload.data,
      };

    case Actions.TIMESLOTS_ERROR:
      return {
        ...state,
        fetchingDetails: false,
        fetchedDetailsSuccess: false,
        fetchedDetailserrorMessage: true,
        errorMessage: action.payload.data,
        timeSlots: [],
      };

    case Actions.TIMESLOTS_WRITE:
      return {
        ...state
      }

    case Actions.TIMESLOT_SELECT_RANGE:
      return {
        ...state,
        ...action.payload.data,
      }

    case Actions.TIMESLOT_SELECT_DAY:
      return {
        ...state,
        ...action.payload.data,
        fetchingDetails: true,
        fetchedDetailsSuccess: false,
        fetchedDetailserrorMessage: false,
      }

    case Actions.STORE_NOTE:
      return {
        ...state,
        note: action.payload.data
      }

    case Actions.TIMESLOTS_SET_START_DAY:
      return {
        ...state,
        startDay: action.payload.data,
      }

    default:
      return state;
  }
}
