import React from "react";
import { centsToDollars } from "../../../utils/prices";

class SummaryLineItem extends React.PureComponent {
  render() {
    let { item } = this.props;
    const hasDiscount = item.discounts && item.discounts.length > 0;
    let hidePrice = false;

    // hide the price for the bundle parent item
    if (item.properties["_groupId"]) {
      const bundleParent = item.properties["_groupId"].split("-")[0];
      if (bundleParent == item.product_id) {
        hidePrice = true;
      }
    }

    // hide the price if the _bundlePrice = 0
    if (item.properties["_bundlePrice"]) {
      if (item.properties["_bundlePrice"] == 0) {
        hidePrice = true;
      }
    }   
  
    return (
      <div className="item-wrapper">
        <div className="item-text">
          <div className="item-title">
            {item.product_title}
            {item.quantity > 1 && <span> x{item.quantity}</span>}
          </div>
        </div>
        {!hidePrice && (
          <div className="item-price">
            {hasDiscount && (
              <div className="item-discount-price">
                {centsToDollars(item.original_line_price)}
              </div>
            )}
            <div className="item-final-price">
              {centsToDollars(item.final_line_price)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SummaryLineItem;
