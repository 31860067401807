import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';

import Footer from './../../components/common/Footer/Footer';

export const mapStateToProps = state => {
  return null
};

export const mapDispatchToProps = dispatch => {
  return null
};

const HeaderContainer = connect(null, null)(
    withRouter(Footer)
);

export default HeaderContainer;