import React, { Component } from 'react';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

class ScheduleRangePicker extends Component {

    getMessage

    render() {
        let { selectedDay,
            slots,
            fetchedSchedulesSuccess,
            fetchedSchedulesError,
            handleTimeSlotSelectRange,
            selectedTimeSlot,
            errorMessage } = this.props;

        let message;

        if (((slots && slots.length === 0) && fetchedSchedulesSuccess) || fetchedSchedulesError) {
            message = <div className="br-1 mb-2"><p className="mb-0 no-slots-available-msg">There are no available slots for this date. Try a different date.</p></div>;
        } else if (errorMessage && errorMessage.length > 0) {
            message = <div className="br-1 mb-2 error"><p className="mb-0 error">{errorMessage}</p></div>;
        } else {
            message = <div className="br-1 mb-2"><p className="mb-0 text-muted">Fetching time slots...</p></div>;
        }

        return (
            <div>
                <p className="mb-2 arrival-window-message">Choose your technician 2-hour arrival window.</p>
                <p className="text-left text-blue date-text"><strong>{selectedDay && (moment(selectedDay).format('LL'))}</strong></p>
                {
                    (fetchedSchedulesSuccess && slots && slots.length > 0) ? (
                        (slots.filter(slot => slot.available).map((slot, i) =>
                            <div key={i} className="bg-color-light pointer-cursor p-2 br-1 mb-2 pos-relative slot-date" onClick={() => { handleTimeSlotSelectRange(slot) }}>
                                <p className="mb-0 disp-inline"><strong>{moment(slot.timeFrom, "HH:mm:ss").format('LT')}<span> - </span>{moment(slot.timeTo, "HH:mm:ss").format('LT')}</strong></p>
                                <span className="slots-radio-btn">
                                    <input type="radio" name="schedule-time" checked={selectedTimeSlot && slot.name === selectedTimeSlot.name} onChange={() => { }} className="from-control" />
                                </span>
                            </div>
                        ))
                    ) : message
                }
            </div>
        )
    }
}

export default ScheduleRangePicker;
