import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

class ScheduleDayPicker extends Component {
    render() {
        const {
            handleTimeSlotSelectDay,
            selectedDay,
            startDay
        } = this.props;
        
        return (
            <Card>
                <CardBody className="p-2 pt-0 pb-0">
                    <DayPicker
                        onDayClick={handleTimeSlotSelectDay}
                        selectedDays={selectedDay}
                        month={selectedDay}
                        fromMonth={startDay}
                        disabledDays={[ {before: startDay }]}
                    />
                </CardBody>
            </Card>
        )
    }
}


export default ScheduleDayPicker
