import React from 'react';
import Loader from 'react-loader-spinner';
import Schedule from './../../components/Schedule/Schedule';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { initializeOrder } from './../../redux/actions/loadActions';
import { withCookies } from 'react-cookie';

class ScheduleContainer extends React.PureComponent {
  componentDidMount() {
    this.props.initialize();
  }

  render() {
    if (this.props.orders.fetchedDetailsSuccess && !this.props.schedules.fetchingDetails) {
      return <Schedule />
    }

    return <div className="spinner-wrapper">
      <p style={{fontSize: "16px"}}><strong>Hang tight while we build your order.</strong></p>

      <Loader type="Oval" color="#0015ea" height={100} width={100} />
    </div>
  }
}

const mapStateToProps = state => {
  return { ...state }
};

const mapDispatchToProps = dispatch => {
  return {
    initialize: () => {
      dispatch(initializeOrder());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(ScheduleContainer)));