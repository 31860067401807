import { TEALIUM_ACCOUNT, TEALIUM_PROFILE, TEALIUM_ENV } from '../inc/config';

const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';
let isScriptLoaded = false;
let loadPromise = {};
let loadTealium;

if (isBrowser) {
  loadTealium = () => {
    loadPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      console.log(`${TEALIUM_ACCOUNT}, ${TEALIUM_PROFILE}, ${TEALIUM_ENV}`);
      script.src = `//tags.tiqcdn.com/utag/${TEALIUM_ACCOUNT}/${TEALIUM_PROFILE}/${TEALIUM_ENV}/utag.js`;
      script.type = 'text/javascript';
      script.async = true;

      // Resolve the promise once the script is loaded
      script.addEventListener('load', () => {
        isScriptLoaded = true;
        resolve();
      });

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        // This is breaking the app, disabling for now
        // reject(new Error(`${script.src} failed to load.`));
      });

      const scripts = document.getElementsByTagName('script')[0];
      scripts.parentNode.insertBefore(script, scripts);
    });
  };

  window.addEventListener('load', () => {
    loadTealium();
  });
}

const analytics = {
  view: function (a, b, c) {
    if (isBrowser) {
      const tryView = () => {
        console.log("analytics.view() called");
        if (isScriptLoaded && window.utag) {
          clearInterval(viewQueu);
          loadPromise.then(() => {
            window.utag.view(a, b, c);
          });
        }
      };
      const viewQueu = setInterval(tryView, 10);
    }
  },
};

export default analytics;
