
import React from 'react';

class BreadCrumb extends React.PureComponent {
    render() {
        const { crumbs } = this.props;
        return (
            <ul className="breadcrumb" style={{backgroundColor: "#FFF"}}>
                {
                    crumbs.map((crumb, index) => {
                        return (<li key={`crumb${index}`} className={'crumb ' + crumb.status}>
                            {
                                index > 0 && <span className='separator'>&gt;</span>
                            }
                            {
                                crumb.link ? (
                                    <a href={crumb.link}>{crumb.text}</a>) :
                                    (crumb.text)
                            }
                        </li>)
                    })
                }
            </ul >
        )
    }
}

export default BreadCrumb;