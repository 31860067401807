import axios from 'axios';
import Cookies from 'universal-cookie';
import jsonp from 'jsonp';
import moment from 'moment';
import { SHOPIFY_API_URL, STORE_URL, DISH_URL, ENV, ENV_LOCALHOST } from '../inc/config';

export const fetchOrderDetails = async () => {
    // This is using jsonp instead of axios because axios does not support jsonp
    console.log(`store-url: ${STORE_URL}`);
    console.log(`env: ${ENV}`);
    return new Promise(function (resolve, reject) {
        jsonp(`${STORE_URL}/cart.json`, null, function (err, data) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

export const fetchTimeSlots = async (day, zipCode, variantIds) => {
    const variantAvailability = JSON.parse(localStorage.getItem('variantAvailability'));
    if (variantAvailability.availableServiceZipcode !== false) {
        return await axios.get(`${DISH_URL}/capacities`, {    
            params: {
                startDate: moment(day).format('YYYY-MM-DD'),
                variantIds: variantIds,
                zipCode: zipCode
            }
        });
    } else {
        return { schedules: [] }
    }
}

export const fetchEarliestTimeSlot = async (variantIds, zipCode) => {
    return await axios.get(`${DISH_URL}/firstAvailable`, {
        params: {
            zipCode: zipCode,
            variantIds: variantIds
        }
    });
}

export const writeAttributes = async (data) => {
    let postUrl = `${STORE_URL}cart/update.js`;
    // console.log(`((( writeAttributes url - ${postUrl} )))`, data);

    return await axios.post(postUrl, {
        attributes: data
    });
}

export const setCartAttributes = async() => {
    try {
        // configure cookies
        let cookies = new Cookies();        
        const agentId = cookies.get("agent_id");
        const clientName = cookies.get("clientname");        
        const parentOrderId = cookies.get("parent");
        const partnerId = cookies.get("partner_id");
        const techId = cookies.get("techid");

        const data = JSON.stringify({
            attributes: {
                "ClientName": (clientName != null)
                    ? clientName
                    : "",
                "Partner ID": (partnerId != null)
                    ? partnerId
                    : "",
                "TechId": (techId != null)
                    ? techId
                    : "",
                "Parent": (parentOrderId != null)
                    ? parentOrderId
                    : "",
                "Agent ID": (agentId != null)
                    ? agentId
                    : ""
            }
        });

        const postUrl = `${STORE_URL}cart/update.js`;
        return await axios.post(postUrl, data, { 
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        var errMsg = `ERROR on scheduler initialization calling update.js`;
        console.error(`${errMsg}: ${error}`);
    } 
}

export const fetchZipCodeVariantAvailability = async (zipcode, items) => {
    let availabilityUrl = null;
    if (ENV === ENV_LOCALHOST) {
        availabilityUrl = SHOPIFY_API_URL;
        console.log(`LOCALHOST: using shopify API url '${availabilityUrl}'`);
    } else {
        availabilityUrl = window["shopifyApiUrl"]
    }

    // configure cookies
    let cookies = new Cookies();
    let now = new Date();
    let expirationDate = null;
    if (now.getMonth() === 11) {
        expirationDate = new Date(now.getFullYear() + 1, 0, 1);
    } else {
        expirationDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    }

    try {
        const getUrl = `${availabilityUrl}/getVariantAvailabilityByZipcode?zipCode=${zipcode}`;    
        let response = await axios.get(getUrl);

        var retVal = {
            validZipcode: true,
            availableServiceZipcode: true,
            zipCode: zipcode
        }

        if (response.data.length === 0) {
            //console.log('*** length of response is 0 ***')
            retVal.availableServiceZipcode = true;
        } else if (response.error) {
            //console.log('*** responded with an error ***')
            retVal.validZipcode = false
        } else if (response.data[0].state === null && response.data[0].variantId === 'ALL') {
            //console.log('*** response marked all for ignored ***')
            retVal.availableServiceZipcode = false;                 
            // write the cookie for non-serviced zip
            // so that the error displays on reload   
            // toggle this cookie setting for prod / local development                                  
            cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: true, expires: expirationDate, sameSite: "none" });
            // cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: false, expires: expirationDate, sameSite: "none" });
        } else {
            retVal.availableServiceZipcode = true;
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < items.length; j++) {
                    // console.log(`*** Checking variant ${items[j].variant_id.toString()}`);
                    if (response.data[i].variantId === items[j].variant_id.toString()) {
                        console.log('** Setting isAvailable to false');
                        retVal.availableServiceZipcode = false;
                        break;
                    }
                }
            }
            console.log(`*** DONE availableServiceZip = ${retVal.availableServiceZipcode} ***`);
        }

        localStorage.setItem('variantAvailability', JSON.stringify(retVal));
        return retVal;

    } catch(err) {
        retVal.validZipcode = false;
        console.error(err);
        console.log(`*** setIsValid End retVal = ${retVal} ***`);
        localStorage.setItem('variantAvailability', JSON.stringify(retVal));       
        return retVal;
    }
}