import React from 'react';
import {Container, Row, Col} from 'reactstrap';
//import {Link} from 'react-router-dom';

export default class NotFound extends React.Component {

  render() {
    return (
        <Container className="pt-3 text-small">
            <Row>
                <Col md={12} className="col-12 ml-auto mt-2 text-center">
                    <h1>404 - No Page Found</h1>
                </Col>
            </Row>
        </Container>
    );
  }
}