import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import TestComponent from "../components/testing/TestComponent";
import EnvironmentInfoComponent from "../components/testing/EnvironmentInfoComponent";
import MainWrapper from './MainWrapper'
import Schedule from '../container/Schedule/ScheduleContainer';
import NotFound from './../components/common/NotFound'
// import DefaultRedirectComponent from './../components/DefaultRedirectComponent'

class Routes extends Component {
    // TODO: Explain how express routes work with react routes both in app proxy and directly

    /*
    https://help.shopify.com/en/api/guides/application-proxies#handling-proxy-requests
    *** App Proxy Request URL Routing ***
    - Example App Proxy Request
        - Proxy configuration - "apps", subpath = "proxy"
        - https://www.test.ontechsmartservices.com/apps/schedule-dev/app/env?param1=test
        - "/app/env?param1=test" is the extra information to be passed into the AWS app
        - https://ontech-extensions.dev.ontechsmartservices.com/proxy/app/env?param1=test

    */

    /*
    NOTE:
    - This page will be pulled into shopify via App Proxy. Therefore the URL will be like "https://myshopifystore.com/app/myappproxy"
    - Because of this, we can only support one route (default route) in the React application!!!
    - If we need to have a URL to do something else, we can have a link that sends a querystring param.
    - These routes DO NOT work when pulled into Shopify via App Proxy.
    - These routs DO NOT work also when hitting the react app via the express-server (/proxy).
    */
    render() {
        return (
            <MainWrapper>
                <main>
                    <Switch>
                        <Route exact path='/proxy/schedule' component={Schedule} />
                        <Route exact path='/proxy/test' component={TestComponent} />
                        <Route exact path='/app/test' component={TestComponent} />
                        <Route exact path='/testcomponent' component={TestComponent} />
                        <Route exact path='/app/test' component={TestComponent} />
                        <Route exact path='/app/env' component={EnvironmentInfoComponent} />
                        <Route exact path='/proxy/notfound' component={NotFound} />
                        <Route exact path='/proxy/app/env' component={EnvironmentInfoComponent} />
                        <Route exact path='/proxy/app/test' component={TestComponent} />
                        <Route path="*" component={Schedule}/>
                    </Switch>
                </main>
            </MainWrapper>
        )
    }
}

export default Routes;
