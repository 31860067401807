import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Header from './../../components/common/Header/Header';

export const mapStateToProps = state => {
    return null
};

export const mapDispatchToProps = dispatch => {
    return null
};

const HeaderContainer = connect(null, null)(
    withRouter(withCookies(Header))
);

export default HeaderContainer;