import * as Actions from '../actionTypes';

const initialOrderState = {
  orderDetails: {},
  fetchingDetails: true,
  fetchedDetailsSuccess: false,
  fetchedDetailsError: false,
};

// receiving response sent by action according to type of action
export default function orderReducers(state = initialOrderState, action) {
  switch (action.type) {
    case Actions.ORDER_DETAILS_REQUEST:
      return {
        fetchingDetails: true,
        fetchedDetailsSuccess: false,
        fetchedDetailsError: false,
        orderDetails: {},
      };

    case Actions.ORDER_DETAILS_SUCCESS:
      return {
        ...action.payload.data,
        fetchingDetails: false,
        fetchedDetailsSuccess: true,
        fetchedDetailsError: false,
      };

    case Actions.ORDER_DETAILS_ERROR:
      return {
        fetchingDetails: false,
        fetchedDetailsSuccess: false,
        fetchedDetailsError: true,
        orderDetails: {},
      };

    default:
      return state;
  }
}
