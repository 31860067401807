import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { centsToDollars } from '../../../utils/prices';
import { SummaryTotal } from './SummaryTotal';
import SummaryLineItem from './SummaryLineItem';

class Summary extends React.PureComponent {
    render() {
        let { fetchingCartSlots, items, tbd, service_date, time_window, service_day, heading, tax, shipping_cost, discount_code, discount_value, } = this.props;
        let subtotal = items && items.length > 0 ? items.reduce((accumulator, item) => accumulator + item.final_line_price, 0) : 0;

        return (
            <Row className="bg-color-light">
                {fetchingCartSlots ? (
                    <Col className="mt-3 text-center">
                        <p className="text-muted">Fetching cart details...</p>
                    </Col>
                ) : (
                        <>
                            <Col md={12} className="bb-1">
                                <div className="p-2 pt-4 mb-2">
                                    <p className="text-left mb-1"><strong>{heading && (heading)}</strong></p>
                                </div>
                            </Col>
                            <Col md={12} >
                                {
                                    items && items.length > 0 ? (
                                        <ul className="items-list">
                                            {items.map((item, i) =>
                                                <li key={`item-${i}`}>
                                                    <SummaryLineItem item={item} />
                                                </li>
                                            )}
                                        </ul>
                                    ) : <div className="text-muted mt-0 text-center">Your cart is empty.</div>
                                }
                            </Col>
                            {
                                service_date && time_window && service_day && (
                                    <Col md={12} className="">
                                        <div className="text-left p-2 bb-1">
                                            <div className="order-summary-items p-2 br-4 text-center">
                                                <Row>
                                                    <Col md={4} className="col-4">
                                                        <div className="display-date-summary-header mb-0 pb-1 pt-1">
                                                            <p className="text-small mb-0">{moment(service_date).format('dddd')}</p>
                                                        </div>
                                                        <div className="display-date-summary-body mt-0 pb-2 ">
                                                            <h2 className="mb-0"><strong>{moment(service_date).format('D')}</strong></h2>
                                                        </div>
                                                    </Col>
                                                    <Col md={8} sm={8} className="text-left col-8">
                                                        <p className="text-muted mb-0">Installation Date</p>
                                                        <p className="mb-0"><strong>{moment(service_date).format('MMM D YYYY')}</strong></p>
                                                        <p className="mb-0"><strong>{time_window}</strong></p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                            <Col md={12}>
                                <div className="text-left p-2 bb-1 pos-relative ">
                                    <div className="p-2 br-4">
                                        <p className="mb-1"><strong>Subtotal <span className="text-right" >{centsToDollars(subtotal, '$0.00')}</span></strong></p>
                                        {this.renderDiscount(discount_code, discount_value)}
                                        <p className="mb-1"><strong>Taxes <span className="text-right" >{tbd ? 'TBD' : centsToDollars(tax, '$0.00')}</span></strong></p>
                                        {this.renderShippingCost(shipping_cost)}
                                    </div>
                                </div>

                                <SummaryTotal {...{ subtotal, tax, shipping_cost, discount_value }} />
                            </Col>
                        </>
                    )}
            </Row>

        )
    }

    renderShippingCost(shipping_cost) {
        const hasShippingCost = shipping_cost && shipping_cost !== undefined && shipping_cost !== 0
        if (hasShippingCost) {
            return (
                <p className="mb-1"><strong>Shipping <span className='text-right'>{centsToDollars(shipping_cost, '$0.00')}</span></strong></p>
            )
        } else {
            return null
        }
    }

    renderDiscount(discount_code, discount_value) {
        const hasDiscount = !!discount_value && discount_value !== 0
        if (hasDiscount) {
            return (
                <p className="mb-1"><strong>{`Discount (${discount_code})`} <span className='text-right'>{'- ' + centsToDollars(discount_value)}</span></strong></p>
            )
        } else {
            return null
        }
    }

}

export default Summary;
