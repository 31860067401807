export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_ERROR = "ORDER_DETAILS_ERROR";

export const TIMESLOTS_REQUEST = "TIMESLOTS_REQUEST";
export const TIMESLOTS_SUCCESS = "TIMESLOTS_SUCCESS";
export const TIMESLOTS_ERROR = "TIMESLOTS_ERROR";
export const TIMESLOTS_WRITE = "TIMESLOTS_WRITE";
export const TIMESLOT_SELECT_RANGE = "TIMESLOTS_SELECT_RANGE";
export const TIMESLOT_SELECT_DAY = "TIMESLOTS_SELECT_DAY";
export const TIMESLOTS_SET_START_DAY = "TIMESLOTS_SET_START_DAY";

export const STORE_NOTE = "STORE_NOTE";
