import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import logger from 'redux-logger'
import thunk from 'redux-thunk';
import { orderReducers, scheduleReducers } from '../redux/reducers/index';
import { ENABLE_REDUX_LOGGING } from './../inc/config';

let middleware = [thunk];
if (ENABLE_REDUX_LOGGING) {
  middleware.push(logger);
}

const reducers = combineReducers({
  orders: orderReducers,
  schedules: scheduleReducers,
});

export default createStore(reducers, compose(applyMiddleware(...middleware)));