import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import 'react-day-picker/lib/style.css';

class ScheduleNote extends React.PureComponent {
    render() {
        let { handleNoteUpdate, note } = this.props;

        return (
            <Form>
                <FormGroup>
                    <Label for="schedule-note">Installation Notes</Label>
                    <Input type="textarea" name="schedule-note" maxLength="4000" className="schedule-note" onBlur={(e) => handleNoteUpdate(e.target.value)} placeholder="Include additional installation notes here" rows="5" defaultValue={note} />
                </FormGroup>
            </Form>
        )
    }
}

export default ScheduleNote;
