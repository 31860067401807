export const base_url = "http://localhost/3000";
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const API_URL = process.env.REACT_APP_API_URL;
export const DISH_URL = process.env.REACT_APP_DISH_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL; // no longer used?
export const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_URL;
export const CHECKOUT_URL_MEMBER = process.env.REACT_APP_CHECKOUT_URL_MEMBER;
export const SHOPIFY_API_URL = process.env.REACT_APP_SHOPIFY_API_URL;
export const ENV = process.env.REACT_APP_ENV;
export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const SERVICE_AGREEMENT_URL =
  process.env.REACT_APP_SERVICE_AGREEMENT_URL;
export const TEALIUM_ACCOUNT = process.env.REACT_APP_TEALIUM_ACCOUNT;
export const TEALIUM_PROFILE = process.env.REACT_APP_TEALIUM_PROFILE;
export const TEALIUM_ENV = process.env.REACT_APP_TEALIUM_ENV;
export const INSTALL_PRODUCT_SKU_TYPES =
  process.env.REACT_APP_INSTALL_PRODUCT_SKU_TYPES; // Is this used anymore?
export const ENABLE_NOTE = process.env.REACT_APP_ENABLE_NOTE === "true";
export const ENABLE_REDUX_LOGGING =
  process.env.REACT_APP_ENABLE_REDUX_LOGGING === "true";
export const REACT_APP_TEALIUM_ENV = process.env.REACT_APP_TEALIUM_ENV;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_DISH_URL = process.env.REACT_APP_DISH_URL;
export const REACT_APP_ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
export const REACT_APP_ROLLBAR_HOST_WHITELIST = process.env.REACT_APP_ROLLBAR_HOST_WHITELIST;

// environments
export const ENV_LOCALHOST = 'localhost';
export const ENV_DEVELOPMENT = 'development';
export const ENV_TEST = 'test';
export const ENV_PRODUCTION = 'production';
