import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { STORE_URL } from '../../inc/config.js';
import { CLIENT_NAME_GOOGLE_NEST } from './../../constants/cartAttributes';
class ScheduleConsentCall extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            membershipExistsInCart: false,
            showTerms: false
        }

        this.handleConsent = this.handleConsent.bind(this);
        this.setConsentAttribute = this.setConsentAttribute.bind(this);        
    }

    componentDidMount() {
        let cookies = new Cookies();
        const clientNameCookie = cookies.get('clientname');
        if ((clientNameCookie === CLIENT_NAME_GOOGLE_NEST || clientNameCookie === encodeURI(CLIENT_NAME_GOOGLE_NEST))) {
            this.setState({
                showTerms: true
            });
        }

        if (this.props.items && this.props.items.length > 0) {
            for (let i = 0; i < this.props.items.length; i++) {
                if (this.props.items[i].product_type === 'Membership') {
                    this.setState({
                        membershipExistsInCart: true
                    });
                    break;
                }
            }
        }
    }

    setConsentAttribute = async (consent) => {
        try {
            return await axios.post(`${STORE_URL}/cart/update.js`, {
                attributes: {
                    'Consent To Call': consent
                },
            });
        } catch(error) {
            console.error(`Error: Consent to call cart/update error - ${error}`);
        }
    }

    handleConsent(event) {
        if (event.target.checked === true) {
            this.setConsentAttribute(true);
        } else {
            this.setConsentAttribute(false);
        }
    }

    renderTerms() {
        let renderResult = null;

        if (this.state.membershipExistsInCart) {
            renderResult = <p>By clicking Next you agree to <a href="/pages/terms-conditions">OnTech+ Membership Terms &amp; Conditions</a></p>
        } else {
            renderResult = <p>By clicking Next you agree to <a href="/pages/terms-conditions">OnTech Terms &amp; Conditions</a></p>
        }
        return renderResult;
    }

    render() {
        if (!this.state.showTerms) {
            return null;
        }

        return (
            <>
                <div className="checkout-terms-link">
                    { this.renderTerms() } 
                </div>
                <div className="consent-to-call">
                    <input type="checkbox" id="consent-check" name="consent-check" value="consent-check" onChange={ this.handleConsent } />
                    <label for="consent-check">
                        Allow OnTech to <a data-toggle="modal" data-target="#callMeModal" id="call-me-modal-link" href="#callMeModal">Call me</a> regarding my service appointment or product purchase
                    </label>
                </div>

                <div class="modal modal-fix fade" id="callMeModal">
                    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Contact Customer Agreement</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                By providing your contact information, including telephone number you authorize OnTech, or its representatives to use this number to contact you regarding OnTech products and services through an automated or predictive dialing system or prerecorded message system, regardless of whether the number is on any do-not-call lists or is otherwise registered as a wireless telephone number. You understand that you do not need to provide a cellular phone number to receive OnTech services.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-default mb-2 mr-2  " data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ScheduleConsentCall;
