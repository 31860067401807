import Cookies from 'universal-cookie';
import React, { Component } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { CLIENT_NAME_SMART_INSURE } from '../../constants/cartAttributes';
import { fetchZipCodeVariantAvailability } from './../../api/clientFuncs';
import Loader from 'react-loader-spinner';
import "./../../assets/styles/zipCode.scss";

class ZipCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            available: false,
            availableServiceZipcode: false,
            fullyLoaded: false,
            items: [],
            validZipcode: true,
            zipCode: 0
        }

        this.changeZip = this.changeZip.bind(this);
        this.resetZip = this.resetZip.bind(this);
    }

    componentDidMount() {
        if (this.props.items) {
            this.setState({
                items: this.props.items
            });
        }

        if (this.props.zipCode) {
            this.setState({
                zipCode: this.props.zipCode
            });
            this.setIsValid(this.props.zipCode, this.props.items);
        } else {
            this.setIsValid(false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.zipCode != null) && (this.state.zipCode !== 0) && (this.state.validZipcode === true)) {
            if ((prevState.validZipcode === false) || (prevState.zipCode === "")) {
                window.location.reload();
            }
        }
    }

    resetZip() {
        let cookies = new Cookies();
        // clear zipcode cookie
        // toggle this cookie setting for prod / local development        
        cookies.set('zipcode', '', { domain: 'ontechsmartservices.com', path: '/', secure: true, sameSite: "none" });
        //cookies.set('zipcode', '', { domain: 'ontechsmartservices.com', path: '/', secure: false, sameSite: "none" });

        this.setState({
            validZipcode: true,
            zipCode: '',
            availableServiceZipcode: true,
            available: false,
            fullyLoaded: false
        });
    }

    focusInput() {
        document.getElementById('product-zipcode').focus();
    }

    setIsValid(zipcode, items) {
        try {
            // eslint-disable-next-line
            let regex = RegExp(/^\d{5}[-\s]?(?:\d{4})?$/);

            //console.log('*** setIsValid Start ***');
            let retVal = false

            if (regex.test(zipcode)) { 
                // configure cookies
                let cookies = new Cookies();
                let now = new Date();
                let expirationDate = null;
                if (now.getMonth() === 11) {
                    expirationDate = new Date(now.getFullYear() + 1, 0, 1);
                } else {
                    expirationDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
                }

                fetchZipCodeVariantAvailability(zipcode, items).then(response => {
                    const zipFetchValues = response;

                    if (zipFetchValues.availableServiceZipcode) {
                        // have a serviced zip code, write the cookie
                        // toggle this cookie setting for prod / local development                            
                        cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: true, expires: expirationDate, sameSite: "none" });
                        // cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: false, expires: expirationDate, sameSite: "none" });
                        
                        let clientNameCookie = cookies.get('clientname');
                        if (!(clientNameCookie === CLIENT_NAME_SMART_INSURE || clientNameCookie === encodeURI(CLIENT_NAME_SMART_INSURE))) {                                
                            this.setState({
                                available: true,
                                fullyLoaded: true
                            });
                            
                        } else {
                            console.log(`(((( smart insure - ${CLIENT_NAME_SMART_INSURE}, ${encodeURI(CLIENT_NAME_SMART_INSURE)} ))))`);
                            console.log('smartinsure skipping earliest install date');
                            retVal = true;
                            this.setState({
                                available: true,
                                fullyLoaded: true
                            });

                        }
                    } else {
                        // toggle this cookie setting for prod / local development
                        cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: true, expires: expirationDate, sameSite: "none" });
                        // cookies.set('zipcode', zipcode, { domain: 'ontechsmartservices.com', path: '/', secure: false, expires: expirationDate, sameSite: "none" });
                    }

                    this.setState({
                        validZipcode: zipFetchValues.validZipcode,
                        availableServiceZipcode: zipFetchValues.availableServiceZipcode,
                        zipCode: zipcode
                    });
                },
                (error) => {
                    console.error(error.message);
                    console.log(`*** setIsValid End retVal = ${retVal} ***`);
                });
            } else {
                this.setState({
                    validZipcode: false
                })
                console.log(`*** setIsValid End retVal = ${retVal} ***`);
            }
        } catch (e) {
            this.setState({
                validZipcode: false
            })
            console.log(`*** setIsValid End retVal = ${false} ***`);
        }
    }    

    changeZip() {
        let items = []
        if (this.props.items) {
            items = this.props.items;
        } else {
            items = [];
        }
        this.setIsValid(document.getElementById('product-zipcode').value, items);
    }    

    handleZipcodeInput = e => {
        var key = e.which ? e.which : e.keyCode;
        if (e.target.value.length >= 5) {
            if (key === 13) {
                e.preventDefault();
                this.changeZip();
            }
        } else {
            if (key === 13) {
                e.preventDefault();
                this.setState({ validZipcode: false });
            }
            e.target.value = e.target.value.replace(/[^0-9]+/g, '');
        }

        if ((e.target.value.length >= 5 && key !== 8 && key !== 37 && key !== 38 && key !== 39 && key !== 40) || (key === 18 || key === 189 || key === 229)) {
            e.preventDefault();
        }
    };
    

    render() {
        let renderResult = null;
        if (this.state.zipCode && this.state.availableServiceZipcode) {
            if (this.state.fullyLoaded) {        
                renderResult = (
                    <div className="zipCode">
                        <div className="product-options-card">
                            <div className="product-options-zip-info">
                                <div className="product-options-zip-status text-success"><span className="fas fa-check-circle"></span></div>
                                <div className="product-options-zip-status-text">
                                    <p>Schedule availability shown based on your ZIP Code: { this.state.zipCode }</p>      
                                </div>
                            </div>
                            <div className="product-options-zip-submit">
                                <span name="resetZipCode" onClick={ this.resetZip }>Change ZIP code</span>
                            </div>
                        </div>   
                    </div>
                );
            } else {
                renderResult = (
                    <div className="product-options-card text-center">
                        <Loader
                            style={{ alignItems: 'center', justifyContent: 'center' }}
                            type="Oval"
                            color="#0015ea"
                            height={25}
                            width={25}
                        />
                    </div>
                )
            }            
        } else if (!this.state.availableServiceZipcode && this.state.validZipcode) {
            renderResult = (
                <div className="zipCode">              
                    <div className="product-options-card">
                        <div className="product-options-zip-info">
                            <div className="text-error text-center">
                                <div>We do not currently have service in { this.state.zipCode }.</div>
                                <div>Please check back soon.</div>
                            </div>
                        </div>
                        <div className="product-options-zip-submit">
                            <span name="resetZipCode" onClick={ this.resetZip }>Change ZIP code</span>
                        </div>
                    </div>
                </div> 
            );
        } else {
            renderResult = (
                <div className="zipCode">                 
                    <div className="product-options-card">
                        <Form>
                            <FormGroup>
                                <div className="product-options-header">Check earliest installation date in your area</div>
                                <div className={this.state.validZipcode ? 'product-options-zip-input' : 'product-options-zip-input Invalid'} onClick={this.focusInput}>
                                    <Label>Enter your ZIP Code</Label>
                                    <Input type="text" id="product-zipcode" pattern="[0-9]{5}" defaultValue={''} onKeyDown={e => this.handleZipcodeInput(e)} onKeyUp={e => this.handleZipcodeInput(e)} />
                                </div>
                                <div className="product-options-zip-message">
                                    <div className={this.state.validZipcode ? 'd-none' : 'd-block text-error'}>Please enter a valid zip code above to verify service in your area</div>
                                </div>
                                <div className="product-options-zip-submit">
                                    <span name="checkZipCode" onClick={ this.changeZip }>Submit ZIP code</span>
                                </div>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            );
        }
        return renderResult;    
    }
}

export default ZipCode