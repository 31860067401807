import * as Actions from '../actionTypes'

export const getOrderDetailsRequest = () => {
    return {
        type: Actions.ORDER_DETAILS_REQUEST
    };
}

export const getOrderDetailsSuccess = (data) => {
    return {
        type: Actions.ORDER_DETAILS_SUCCESS,
        payload: {
            data,
        }
    };
}

export const getOrderDetailsError = (err) => {
    return {
        type: Actions.ORDER_DETAILS_ERROR,
        payload: {
            data: {}
        }
    };
}

