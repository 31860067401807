import React, {PureComponent} from 'react';
import HeaderContainer from './../container/Header/HeaderContainer'
import FooterContainer from './../container/Footer/FooterContainer'

class MainWrapper extends PureComponent {
  render() {
    return (
        <>
            <HeaderContainer />
              {this.props.children}
            <FooterContainer />
        </>
    )
  }
}

export default MainWrapper;
