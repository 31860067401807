import * as Actions from "./../actionTypes";
import * as clientFuncs from "./../../api/clientFuncs";
import Cookies from "js-cookie";
import { AGENT_ID } from "../../constants/cookies";
import moment from "moment";
import {
    CHECKOUT_URL,
    CHECKOUT_URL_MEMBER,
    INSTALL_PRODUCT_SKU_TYPES,
    ENV,
    ENV_LOCALHOST,
} from "./../../inc/config";
import {
    SERVICE_INSTALL_PRODUCT_SKUS,
    SERVICE_SCHEDULED_DATE,
    SERVICE_SCHEDULED_FROM,
    SERVICE_SCHEDULED_TO,
    SERVICE_SCHEDULED_WINDOW,
    SERVICE_NOTE,
    TECH_DELIVERED,
    PARTNER_ID,
    CLIENT_NAME_GOOGLE_NEST,
    PARTNER_ID_GOOGLE_NEST,
    CLIENT_NAME,
} from "./../../constants/cartAttributes";

let genericError = `Sorry, there was an issue retrieving dates. Please try again later.`;

export const getTimeSlotsRequest = () => {
    return {
        type: Actions.TIMESLOTS_REQUEST,
    };
};

export const getTimeSlotsSuccess = (data) => {
    return {
        type: Actions.TIMESLOTS_SUCCESS,
        payload: {
            data,
        },
    };
};

export const storeNote = (note) => {
    return {
        type: Actions.STORE_NOTE,
        payload: {
            data: note,
        },
    };
};

export const getTimeSlotsError = (
    errorMessage = "We're unable to retrieve installation dates at this time. Please try again later."
) => {
    return {
        type: Actions.TIMESLOTS_ERROR,
        payload: {
            data: errorMessage,
        },
    };
};

export const selectTimeSlotRange = (timeSlot) => {
    return {
        type: Actions.TIMESLOT_SELECT_RANGE,
        payload: {
            data: {
                selectedTimeSlot: timeSlot,
            },
        },
    };
};

export const selectTimeSlotDay = (day) => {
    return {
        type: Actions.TIMESLOT_SELECT_DAY,
        payload: {
            data: {
                selectedDay: day,
                selectedTimeSlot: {},
            },
        },
    };
};

export const setStartDay = (day) => {
    return {
        type: Actions.TIMESLOTS_SET_START_DAY,
        payload: {
            data: day,
        },
    };
};

export const selectTimeSlotDayDefaultRange = (day) => {
    return async (dispatch, state) => {
        try {
            const { zipCode, variantIdsStr } = state().orders;
            let res = await clientFuncs.fetchTimeSlots(
                day,
                zipCode,
                variantIdsStr
            );

            if (!res.data) {
                throw new Error("No timeslot available");
            }

            if ("error" in res.data && res.data["error"] != null) {
                dispatch(
                    getTimeSlotsError(
                        `${genericError} (${res.data.error.code})`
                    )
                );
                return;
            }

            const selectedDay = res.data.schedules[0];

            if (selectedDay === undefined) {
                dispatch(getTimeSlotsError("Selected day is not available"));
                return;
            }

            const timeSlot = selectedDay.times.find((range) => {
                return range.available;
            });

            if (timeSlot === undefined) {
                dispatch(getTimeSlotsError("Selected day is not available"));
                return;
            }

            dispatch(selectTimeSlotRange(timeSlot));
            dispatch(
                getTimeSlotsSuccess(
                    selectedDay.times.filter((x) => x.available)
                )
            );
        } catch (err) {
            if (err && err.response && err.response.status) {
                dispatch(
                    getTimeSlotsError(
                        `${genericError} (${err.response.status})`
                    )
                );
            } else {
                dispatch(getTimeSlotsError(`${genericError}`));
            }
        }
    };
};

export const completeScheduling = (
    selectedDay,
    selectedTimeSlot,
    orderDetails,
    hasTechId,
    techDelivered,
    isMember,
    isGoogle,
    isLoggedIn
) => {
    return async (dispatch, state) => {
        let skusStr = "";
        if (
            orderDetails &&
            orderDetails.items &&
            orderDetails.items.length > 0
        ) {
            if (!INSTALL_PRODUCT_SKU_TYPES) {
                console.error(
                    "REACT_APP_INSTALL_PRODUCT_SKU_TYPES was not configured in environment variables"
                );
            } else {
                let installProductSkuTypes = INSTALL_PRODUCT_SKU_TYPES.split(
                    ","
                );
                skusStr = orderDetails.items
                    .filter((item) =>
                        installProductSkuTypes.includes(item.product_type)
                    )
                    .map((item) =>
                        item.sku.length > 0
                            ? item.sku
                            : `MISSING_SKU: ${item.product_title}`
                    )
                    .join(",");
            }
        }

        let scheduleDateMoment = moment(selectedDay);
        let scheduleDate = scheduleDateMoment.format("YYYY-MM-DD");

        let data = {
            [SERVICE_SCHEDULED_DATE]: scheduleDate,
            [SERVICE_SCHEDULED_FROM]: moment(
                selectedTimeSlot.timeFrom,
                "HH:mm:ss"
            ).format("LT"),
            [SERVICE_SCHEDULED_TO]: moment(
                selectedTimeSlot.timeTo,
                "HH:mm:ss"
            ).format("LT"),
            [SERVICE_SCHEDULED_WINDOW]: `${moment(
                selectedTimeSlot.timeFrom,
                "HH:mm:ss"
            ).format("LT")} - ${moment(
                selectedTimeSlot.timeTo,
                "HH:mm:ss"
            ).format("LT")}`,
            [SERVICE_NOTE]: state().schedules.note,
            [SERVICE_INSTALL_PRODUCT_SKUS]: skusStr,
        };

        if (hasTechId) {            
            data[TECH_DELIVERED] = techDelivered;
            if (techDelivered) {
                Cookies.set('tech_delivered', 'true', { domain: 'ontechsmartservices.com', path: '/', secure: true, sameSite: "none" });
            } else {
                Cookies.remove('tech_delivered', {"domain": ".ontechsmartservices.com"});
            }
        }

        /*
        In the google profinder flow, we're skipping cart as the landing page acts as the cart. The cart normally
        writes the cart attributes. Therefore, we need to do it here. Also, we need partner ID because checkout
        needs to know this is the google profinder flow and client name not unique while partner ID is.
        */
        if (isGoogle) {
            console.log(`*** isGoogle = true ***`);
            data[CLIENT_NAME] = CLIENT_NAME_GOOGLE_NEST;
            data[PARTNER_ID] = PARTNER_ID_GOOGLE_NEST;
        }

        try {
            if (ENV === ENV_LOCALHOST) {
                console.log("Writing Cart Attributes", data);
            }

            let resp = await clientFuncs.writeAttributes(data);

            // Determine where to go next
            var forceMemberLogin =
                (
                    window["isLoggedIn"] === false &&
                    (
                        Cookies.get("ontech_oss_parentOrderMember") === "true" && 
                        Cookies.get(AGENT_ID) === undefined
                    ) && (
                        window["enableForcedLoginForParentMember"] ||
                        typeof Cookies.get('ontech_oss_parentOrderMembershipInfo') === 'undefined'
                    )
                ) || 
                (
                    window["isLoggedIn"] === false && 
                    Cookies.get("ontech_sha_inCart") === "true"
                );

            if ((!isLoggedIn && isMember) || forceMemberLogin) {
                await writeTimeSlotSuccessMember(resp);
            } else {
                // go to checkout
                await writeTimeSlotSuccess(resp);
            }
        } catch (err) {
            console.error("Error writing attributes to cart", err);
        }
    };
    //return null;
};

export const writeTimeSlotSuccess = () => {
    window.location = CHECKOUT_URL;
};

export const writeTimeSlotSuccessMember = () => {
    window.location = CHECKOUT_URL_MEMBER;
};
