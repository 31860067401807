import React from 'react';
// import queryString from 'querystring'
// import DayPicker from "react-day-picker";
// import 'react-day-picker/lib/style.css';
import { STORE_URL } from '../../inc/config';
import jsonp from 'jsonp';
// import { request } from 'http';
// const request_promise = require('request-promise');


export default class TestComponent extends React.Component {
    componentDidMount() {
        console.log('TestComponent -> componentDidMount()');
        // const values = queryString.parse(this.props.location.search)
        // console.log(values.filter) // "top"
        // console.log(values.origin) // "im"
    }

    fetchOrderDetails = async () => {
        // alert('attempting to get cart');
        return new Promise(function (resolve, reject) {
            console.log('calling cart.json at ' + STORE_URL);
            jsonp(`${STORE_URL}cart.json`, null, function (err, data) {
                if (err) {
                    console.log('err', err)
                    alert('error retrieving cart')
                    reject(err);
                } else {
                    console.log('*** Cart Details ***');
                    console.log(data);
                    alert('Retrieved cart, see console');
                    resolve(data);
                }
            });
        });
    }

    updateOrderNotes = async () => {
        /*
            See https://help.shopify.com/en/themes/development/getting-started/using-ajax-api
            jQuery.post('/cart/update.js', { note: 'Installation appointment info here!});
        */
        // let postUrl = STORE_URL + 'cart/update.js';
        // alert('attempting to update cart notes');

        // PROBLEM - we can't do this because shopify's ajax api doesn't support CORS so it's not letting us call this

        // var options = {
        //     method: 'POST',
        //     uri: postUrl,
        //     body: {
        //         note: 'Installation appointment info here!'
        //     },
        //     json: true // Automatically stringifies the body to JSON
        // };

        // request_promise(options)
        //     .then(function (parsedBody) {
        //         console.log('post success ' + parsedBody);
        //     })
        //     .catch(function (err) {
        //         // POST failed...
        //         console.log('post FAIL ' + err);
        //     });


    }

    render() {
        return (
            <div className="test-content">
                <h2>Test Component</h2>
                <div className='blue-border'>
                    {/* This is content within the test component. Message = '{this.props.message}' */}
                    {/* <br/> */}
                    {/*{'{'}{'{'} cart.item_count {'}'}{'}'}*/}
                    {/* This doesn't work, view source, it's not the tag. keep looking into this */}
                    {/* {'{{ cart.item_count }}'} */}

                    {/* <div>
                        <DayPicker/>
                    </div> */}

                    <div>
                        <button onClick={this.fetchOrderDetails}>Get Cart</button>
                    </div>
                    
                    {/* <div>
                        <button onClick={this.updateOrderNotes}>Update Order Note</button>
                    </div> */}
                </div>
            </div>
        );
    }
}
