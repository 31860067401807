import React from 'react';
// import { STORE_URL } from '../../../inc/config';

export default class Footer extends React.Component {
    // state = {
    //   isOpen: false
    // };
    // toggleNavbar = () => {
    //     this.setState({
    //       isOpen: !this.state.isOpen
    //     });
    // }

  render() {
    return (
        <footer>
            {/*<b>Footer Here</b>*/}
        </footer>
   );
  }
}