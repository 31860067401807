import React from 'react';

export default class Header extends React.PureComponent {
    render() {
        return (
            <script type="application/javascript">
                var utag_data = {};
            </script>
        );
    }
}
