import Rollbar from 'rollbar';

var rollbarEnv = process.env.REACT_APP_ROLLBAR_ENVIRONMENT;
var rollbarUtilWhitelist = process.env.REACT_APP_ROLLBAR_UTIL_HOST_WHITELIST;
var _rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostWhiteList: JSON.parse(rollbarUtilWhitelist),
  payload: {
      environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT
  }
};

export const RollbarUtil = new Rollbar(_rollbarConfig);

/* RollBar Logging */
export const logDebug = function(message, data) {
    if (typeof(RollbarUtil) != 'undefined') {
        if (data) {
            RollbarUtil.debug(message, data); 
        } else {
            RollbarUtil.debug(message); 
        }        
    } else {
        console.log(message);
    }
}

export const logInfo = function(message, data) {
    if (typeof(RollbarUtil) != 'undefined') {
        if (data) {
            RollbarUtil.info(message, data); 
        } else {
            RollbarUtil.info(message); 
        }        
    } else {
        console.info(message);
    }
}

export const logWarning = function(message, data) {
    if (typeof (RollbarUtil) != 'undefined') {
        if (data) {
            RollbarUtil.warning(message, data); 
        } else {
            RollbarUtil.warning(message); 
        }        
    } else {
        console.warn(message);
    }
}

export const logCritical = function(message, data) {
    if (typeof(RollbarUtil) != 'undefined') {
        if (data) {
            RollbarUtil.critical(message, data); 
        } else {
            RollbarUtil.critical(message); 
        }        
    } else {
        console.critical(message);
    }
}

export const logError = function (message, error, data) {
    if (typeof (RollbarUtil) != 'undefined') {
        if (data && error) {
            var logData = {
                "data": data,
                "error": error
            };
            RollbarUtil.error(message, logData);
        } else if (error) {
            RollbarUtil.error(message, error);
        } else {
            RollbarUtil.error(message);
        }            
    } else {
        console.error(message);
    }
}