export const SERVICE_SCHEDULED_DATE = "Scheduled Date";
export const SERVICE_SCHEDULED_FROM = "Appointment Time From";
export const SERVICE_SCHEDULED_TO = "Appointment Time To";
export const SERVICE_SCHEDULED_WINDOW = "Appointment Window";
export const SERVICE_INSTALL_PRODUCT_SKUS = "Install Product SKUs";
export const SERVICE_NOTE = "Customer Note";
export const TECH_DELIVERED = "TechDelivered"; // The attribute has no spaces and is not consistent with the other ones
export const CLIENT_NAME = "ClientName";
export const PARTNER_ID = "Partner ID";

// Product level constants 
export const CLIENT_NAME_D2C_RING = "D2C - Ring";
export const CLIENT_NAME_SMART_INSURE = "D2C - SmartInsure";
export const CLIENT_NAME_GOOGLE_NEST = "D2C - Google Nest";

export const PARTNER_ID_GOOGLE_NEST = "D2C-Google-Nest";