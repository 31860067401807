import * as clientFuncs from './../../api/clientFuncs';
import { getOrderDetailsRequest, getOrderDetailsSuccess, getOrderDetailsError } from './orderActions';
import { selectTimeSlotDay, selectTimeSlotRange, getTimeSlotsSuccess, storeNote, getTimeSlotsError, setStartDay } from './scheduleActions';
import { SERVICE_NOTE, CLIENT_NAME, CLIENT_NAME_SMART_INSURE, CLIENT_NAME_D2C_RING } from './../../constants/cartAttributes';
import { ZIP_CODE } from './../../constants/cookies';
import { logError } from './../../utils/rollbar_util'

import Cookies from 'js-cookie';
import moment from 'moment';
import 'url-search-params-polyfill';

const genericError = `Sorry, there was an issue retrieving dates. Please try again later.`;
const queryParamsHasDebug = new URLSearchParams(window.location.search).has("debug");

const attributeStartDates = [
    {
        "key": CLIENT_NAME_D2C_RING,
        "date": moment().format("YYYY-MM-DD")
    },
    {
        "key": CLIENT_NAME_SMART_INSURE,
        "date": moment().startOf('day').add(8, 'days')
    }
]

// Helper function to pull the start date from the defined attribute start dates
const getStartDateFromAttributes = (schedules, key) => {
    let startDay = attributeStartDates.find(x => x.key === key);
    if (startDay !== undefined) {
        return startDay.date
    }
    return schedules.startDay;
}

// Helper function to get the start day from the schedules and attributes
const getStartDay = (schedules, attributes) => {
    // Takes *only* the objects from the start dates that exist in the future. meaning dates that are passed will not apply startDate 
    let futureDates = attributeStartDates.filter(x => moment() < x.date);

    // These will only have an effect when debug exists in query params
    if (queryParamsHasDebug) {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams && searchParams.has("startDay")) {
            let startDay = searchParams.get("startDay");
            return new Date(moment(startDay).toDate());
        } else if (futureDates && futureDates.length > 0 && searchParams && searchParams.has("clientName")) {
            return getStartDateFromAttributes(schedules, searchParams.get("clientName"));
        }
    }

    if (futureDates && futureDates.length > 0 && CLIENT_NAME in attributes) {
        return getStartDateFromAttributes(schedules, attributes[CLIENT_NAME]);
    }
}

const getZip = () => {
    if (!queryParamsHasDebug) {
        return Cookies.get(ZIP_CODE);
    }

    if (new URLSearchParams(window.location.search).has(ZIP_CODE)) {
        let selectedDay = new URLSearchParams(window.location.search).get(ZIP_CODE);
        return selectedDay;
    }

    return '07450';
}


export const initializeOrder = () => {
    return async (dispatch, getState) => {
        dispatch(getOrderDetailsRequest());
        try {
            const orderDetailRes = await clientFuncs.fetchOrderDetails();

            if (!orderDetailRes || !orderDetailRes.items) {
                throw new Error("Response requires items");
            }
            const zipCode = getZip();
            
            await clientFuncs.fetchZipCodeVariantAvailability(zipCode, orderDetailRes.items);

            const variantIds = orderDetailRes.items.reduce((result, item) => {
                for (var i = 0; i < item.quantity; i++) {
                    result.push(item.variant_id);
                }
                return result;
            }, []);
            const variantIdsStr = variantIds.join(',');

            const clientName = Cookies.get("clientname");
            if (clientName === "D2C - Rocket Mortgage" || clientName === "D2C%20-%20Rocket%20Mortgage") {
                await clientFuncs.setCartAttributes();
            }

            if (orderDetailRes.attributes && SERVICE_NOTE in orderDetailRes.attributes) {
                const note = orderDetailRes.attributes[SERVICE_NOTE];
                dispatch(storeNote(note));
            }

            dispatch(getOrderDetailsSuccess({ ...orderDetailRes, variantIds, variantIdsStr, zipCode }));
            dispatch(initializeSchedule(zipCode, variantIdsStr));
        }
        catch (err) {
            logError('Scheduler loadActions getOrderDetailsError', err);
            if (err && err.response && err.response.status) {
                dispatch(getOrderDetailsError(`${genericError} (${err.response.status})`));
            }
            else {
                dispatch(getOrderDetailsError(`${genericError}`));
            }
        }
    }
}

const initializeSchedule = (zipCode, variantIdsStr) => {
    return async (dispatch, getState) => {
        try {
            const startDay = getStartDay(getState().schedules, getState().orders.attributes);
            const timeSlotRes = await clientFuncs.fetchTimeSlots(startDay, zipCode, variantIdsStr);

            if (!timeSlotRes.data || !timeSlotRes.data.length < 1) {
                dispatch(getTimeSlotsError("No timeslot available"));
                return;
            }
            const selectedDay = timeSlotRes.data.schedules.find(day => day.available);
            if (selectedDay === undefined) {
                dispatch(getTimeSlotsError("No timeslot available"));
                return;
            }
            dispatch(setStartDay(moment(selectedDay.date).toDate()));

            const firstTimeSlot = selectedDay.times.find(time => time.available);

            // This scenario should never happen if the API data is consistent with the day-level available flag, but is put here as a safeguard
            // Removed this state per MM
            /*if (firstTimeSlot === undefined) {
                dispatch(getTimeSlotsError("No timeslot available"));
                return;
            }*/

            dispatch(selectTimeSlotDay(moment(selectedDay.date).toDate()));
            dispatch(selectTimeSlotRange(firstTimeSlot));
            dispatch(getTimeSlotsSuccess(selectedDay.times));
        } catch (err) {
            logError('Scheduler loadActions getTimeSlotsError', err);
            if (err && err.response && err.response.status) {
                dispatch(getTimeSlotsError(`${genericError} (${err.response.status})`));
            }
            else {
                dispatch(getTimeSlotsError(`${genericError}`));
            }
        }
    }
}