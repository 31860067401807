export const fromCents = value => (
  (value / 100.0).toFixed(2)
);

export const centsToDollars = (value, zeroValue, prepend) => {
  const onZero = zeroValue || "";
  const prefix = prepend || "$";
  if (!value || value === 0) return onZero;
  return prefix + fromCents(value);
};

export const getProductsInfo = items => {
    let result = [];
    items.forEach(itemsList => {
        result = result.concat(itemsList);
    })
    return result;
};

export const calculateSubtotal = items => {
  let subTotal = 0;
  if (items && items.length > 0) {
    items.forEach(item => {
      
      subTotal += item.final_line_price;
    });
  }
  return subTotal;
};
