export const COOKIE_SHIPPING_COST = 'shippingCost';
export const COOKIE_DISCOUNT_CODE = 'discountCode';
export const COOKIE_DISCOUNT_VALUE = 'discount';
export const TAX = 'tax';
export const EMAIL = 'customer_email';
export const RECURLY_INVOICE = 'recurly_invoice';
export const CART_ITEMS_COUNT = 'cart_items_count';
export const AGENT_ID = 'agent_id';
export const TECH_ID = 'techid';
export const PARENT = 'parent';
export const ZIP_CODE = 'zipcode';
export const CLIENT_NAME = 'clientname';